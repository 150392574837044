
@tailwind base;

@tailwind components;

// @import 'paypal_cardfields';

#drivescout-cart-wrapper {
  .go-to-checkout {
    @apply block text-center w-full rounded shadow-lg font-semibold uppercase p-2;
    background: hsl(156, 73%, 74%);
    color: hsl(170, 97%, 15%)
  }
}

.cart {
  @apply flex flex-col flex-wrap;
  .cart-item {
    @apply flex flex-col w-full mb-8 bg-white shadow-lg rounded;
    &:first-child {
      @apply ml-0;
    }
  }
}
@screen sm {
  .cart {
    @apply flex-row;
    .cart-item {
      // @apply w-1/4 ml-8;
    }
  }

}

#drivescout-checkout {
  label {
    @apply block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2;
  }
  .form-group {
    @apply mb-4;
  }
  input.form-control {
    @apply block w-full shadow-sm sm:text-sm border-gray-300 rounded-md;
    &:focus {
      @apply ring-indigo-500 border-indigo-500
    }
  }
  select.form-control {
    @apply block w-full shadow-sm sm:text-sm border-gray-300 rounded-md;
    &:focus{
      @apply ring-indigo-500 border-indigo-500;
    }
  }
  .help {
    &.is-danger {
      @apply text-red-500 text-xs italic;
    }
  }


  .remove-from-cart {
    @apply text-red-500 bg-transparent border border-red-500 block text-xs font-semibold rounded px-2 py-1;
    &:hover {
      @apply text-white bg-red-500;
    }
  }

  .section-header {
    @apply block w-full bg-transparent text-white mb-0 py-2 cursor-pointer;
    button {
      @apply block w-full text-2xl text-gray-600 text-left bg-transparent mb-8 font-semibold tracking-wider;
    }
  }
  .slide-panel {
    display: none;
    max-height: 100%;
    overflow-y: hidden;
    transition: max-height 0.5s ease-in-out;
    @apply p-2 border-gray-500 border border-solid;
    &.slide-panel-open {
      display: block;
    }
  }



  .drivescout-checkout-button {
    @apply block text-center w-full py-2 rounded shadow-lg font-semibold uppercase;
    background: #65D6AD;
    color:#014D40;
  }

}

#drivescout-app {
  padding-top: 0px;
  .drivescout-products {
    .card {
      @apply shadow rounded-t-none
      }
    .product-name {
    }
    .product-description {

    }
    .price {

    }
  }

  .add-to-cart {
    @apply bg-blue-500 text-white text-base py-1 px-2 rounded shadow-md;
    &:hover {
      @apply bg-blue-700 text-white;
    }
  }

  .add-to-cart-wrapper {
    .add-to-cart-alert {
      @apply block text-red-500 text-sm
    }
  }

  .tooltip .tooltip-text {
    @apply w-auto invisible p-4 absolute z-50 inline-block ml-4 text-sm rounded-lg bg-gray-700 text-white;
  }

  .tooltip:hover .tooltip-text {
    @apply visible;
  }
}

@tailwind utilities;


[v-cloak] {display: none}